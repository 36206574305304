import {
  CHANNEL_FACEBOOK,
  CHANNEL_LINKEDIN,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_INSTAGRAM,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_PINTEREST,
  CHANNEL_TWITTER,
  CHANNEL_SLACK,
  CHANNEL_THREADS,
} from '@/destinations/constants/Constants';
import StoryChief from '@/storychief';

function thumbPath(iconName) {
  // The path is relative to the assetBaseUrl property in our PhotoEditorSDKUI config.
  return StoryChief.asset(`images/photoeditor/${iconName}.svg`);
}

export const SOCIAL_CROP_SIZES = {
  original: {
    identifier: 'custom_original',
    thumbnailURI: thumbPath('original'),
    name: 'Original',
    ratio: null,
    lockDimensions: false,
    isDisabled: true,
  },
  common: {
    square: {
      identifier: 'custom_transform_social_square',
      thumbnailURI: thumbPath('square'),
      name: 'Square',
      ratio: 1 / 1,
      lockDimensions: false,
    },
  },
  facebook: {
    portrait: {
      identifier: 'custom_transform_social_facebook_portrait',
      thumbnailURI: thumbPath('facebook-portrait'),
      name: 'Portrait',
      ratio: 1 / 1.91,
      lockDimensions: false,
    },
    landscape: {
      identifier: 'custom_transform_social_facebook_landscape',
      thumbnailURI: thumbPath('facebook-landscape'),
      name: 'Landscape',
      ratio: 1.91 / 1,
      lockDimensions: false,
    },
  },
  instagram: {
    portrait: {
      identifier: 'custom_transform_social_instagram_portrait',
      thumbnailURI: thumbPath('instagram-portrait'),
      name: 'Portrait',
      ratio: 4 / 5,
      lockDimensions: false,
    },
    landscape: {
      identifier: 'custom_transform_social_instagram_landscape',
      thumbnailURI: thumbPath('instagram-landscape'),
      name: 'Landscape',
      ratio: 16 / 9,
      lockDimensions: false,
    },
  },
  linkedin: {
    portrait: {
      identifier: 'custom_transform_social_linkedin_portrait',
      thumbnailURI: thumbPath('linkedin-portrait'),
      name: 'Portrait',
      ratio: 1 / 1.91,
      lockDimensions: false,
    },
    landscape: {
      identifier: 'custom_transform_social_linkedin_landscape',
      thumbnailURI: thumbPath('linkedin-landscape'),
      name: 'Landscape',
      ratio: 1.91 / 1,
      lockDimensions: false,
    },
  },
  twitter: {
    post: {
      identifier: 'custom_transform_social_twitter_post',
      thumbnailURI: thumbPath('twitter-post'),
      name: 'Post',
      ratio: 1.77 / 1,
      lockDimensions: false,
    },
  },
  pinterest: {
    pin: {
      identifier: 'custom_transform_social_pinterest_pin',
      thumbnailURI: thumbPath('pinterest-pin'),
      name: 'Pin',
      ratio: 2 / 3,
      lockDimensions: false,
    },
    pinAlt: {
      identifier: 'custom_transform_social_pinterest_pin_alt',
      thumbnailURI: thumbPath('pinterest-pin-alt'),
      name: 'Pin (alt)',
      ratio: 3 / 4,
      lockDimensions: false,
    },
  },
  googleBusiness: {
    post: {
      identifier: 'custom_transform_social_google_my_business_post',
      thumbnailURI: thumbPath('google-business-post'),
      name: 'Post',
      ratio: 9 / 5,
      lockDimensions: false,
    },
  },
  slack: {
    post: {
      identifier: 'custom_transform_social_slack_post',
      thumbnailURI: thumbPath('slack-post'),
      name: 'Post',
      ratio: 2 / 1,
      lockDimensions: false,
    },
  },
  threads: {
    portrait: {
      identifier: 'custom_transform_social_threads_portrait',
      thumbnailURI: thumbPath('threads-portrait'),
      name: 'Portrait',
      ratio: 9 / 16,
      lockDimensions: false,
    },
    landscape: {
      identifier: 'custom_transform_social_threads_landscape',
      thumbnailURI: thumbPath('threads-landscape'),
      name: 'Landscape',
      ratio: 16 / 9,
      lockDimensions: false,
    },
  },
};

export const SOCIAL_RECOMMENDED_MIN_SIZES = {
  facebook: {
    width: 600,
    height: 315,
  },
  instagram: {
    width: 320,
    height: 566,
  },
  twitter: {
    width: 600,
    height: 335,
  },
  googleBusiness: {
    width: 497,
    height: 373,
  },
  pinterest: {
    width: 200,
    height: 300,
  },
  threads: {
    width: 320,
    height: 320,
  },
};

export const SOCIAL_MAX_SIZES = {
  facebook: {
    width: 2048,
    height: 2048,
  },
  instagram: {
    width: 1080,
    height: 1350,
  },
  twitter: {
    // Twitter API supports up to 4096 x 4096, but for simplicity we limit it to 2048.
    width: 2048,
    height: 2048,
  },
  linkedin: {
    width: 2048,
    height: 1920,
  },
  googleBusiness: {
    width: 2048,
    height: 2048,
  },
  slack: {
    // Slack does not have a size limit, but for simplicity we limit it to 2048.
    width: 2048,
    height: 2048,
  },
  pinterest: {
    width: 2048,
    height: 2048,
  },
  threads: {
    width: 1440,
    height: 14_400, // Threads doesn't have a max height, but a max aspect ratio of 10/1
  },
};

export const SOCIAL_ASPECT_RATIOS = {
  // Minimum, maximum
  facebook: [4 / 5, 1.91 / 1],
  instagram: [4 / 5, 1.91 / 1],
  twitter: [3 / 4, 2 / 1],
  linkedin: [2 / 3, 3 / 1],
  googleBusiness: [4 / 3, 9 / 5],
  threads: [0, 10 / 1],
  // Channels without aspect ratio limits. Values are required for all channels so the users can
  // resize the image to the max size limit.
  pinterest: [0, 99],
  slack: [0, 99],
};

export const CROP_PROGRESS = {
  start: 10,
  urlsConverted: 40,
  dataGenerated: 60,
  imagesUploaded: 85,
};

export const CHANNELS_WITH_NATIVE_LINK_MODIFY = [
  CHANNEL_FACEBOOK,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_LINKEDIN,
  CHANNEL_INSTAGRAM,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_PINTEREST,
];

export const STORY_SHARE_DOMAIN_CHANNELS = [
  CHANNEL_FACEBOOK,
  CHANNEL_TWITTER,
  CHANNEL_SLACK,
  CHANNEL_THREADS,
];

export const LINK_SHARE_DOMAIN_CHANNELS = [CHANNEL_SLACK];

// The deviation was added as smartcrop (used for smart cropping images) can output a slightly
// different aspect ratio than expected.
const allowedDeviation = 0.009;

export const FACEBOOK_VIDEO_TYPES = {
  video: {
    value: 'video',
    label: 'Video',
  },
  reel: {
    value: 'reel',
    label: 'Reel',
  },
};

export const FACEBOOK_ASPECT_RATIO_LIMITS = {
  reels: {
    min: 9 / 16,
    max: 9 / 16,
  },
};

export const FACEBOOK_DURATION_LIMITS = {
  reels: {
    min: 3,
    max: 90,
  },
};

export const FACEBOOK_RESOLUTIONS = {
  reels: {
    width: 540,
    height: 960,
  },
};

export const INSTAGRAM_ASPECT_RATIO_LIMITS = {
  images: {
    min: 4 / 5 - allowedDeviation,
    max: 16 / 9 + allowedDeviation,
  },
  reels: {
    min: 0.01 / 1,
    max: 10 / 1,
  },
  stories: {
    min: 0.1 / 1,
    max: 10 / 1,
    recommended: 9 / 16,
  },
};

export const INSTAGRAM_DURATION_LIMITS = {
  reels: {
    min: 3,
    max: 15 * 60,
  },
  stories: {
    min: 3,
    max: 60,
  },
};

export const INSTAGRAM_MEDIA_TYPES = {
  post: {
    value: 'post',
    label: 'Post',
  },
  story: {
    value: 'story',
    label: 'Story',
  },
};

export const INSTAGRAM_VIDEO_TYPES = {
  reel: {
    value: 'reel',
    label: 'Reel',
  },
  story: {
    value: 'story',
    label: 'Story',
  },
};

export const INSTAGRAM_VIDEO_SIZES = {
  video: 100 * 1024 * 1024, // 100MB
  reels: 1000 * 1024 * 1024, // 1GB
  stories: 100 * 1024 * 1024, // 100MB
};

export const LINKEDIN_UPLOAD_SIZES = {
  document: 100 * 1024 * 1024, // 100MB
  numberOfPages: 300,
};

export const INSTAGRAM_RESOLUTIONS = {
  video: 1920,
  reels: 1920,
  stories: 1920,
};

export const INSTAGRAM_IMAGE_SIZES = {
  stories: 8 * 1024 * 1024, // 8MB
};

export const TIKTOK_PRIVACY_LEVEL_OPTIONS = {
  PUBLIC_TO_EVERYONE: {
    value: 'PUBLIC_TO_EVERYONE',
    label: 'Everyone',
  },
  MUTUAL_FOLLOW_FRIENDS: {
    value: 'MUTUAL_FOLLOW_FRIENDS',
    label: 'Friends',
  },
  SELF_ONLY: {
    value: 'SELF_ONLY',
    label: 'Only me',
  },
  FOLLOWER_OF_CREATOR: {
    value: 'FOLLOWER_OF_CREATOR',
    label: 'Followers',
  },
};

export const TIKTOK_INTERACTION_OPTIONS = {
  stitch: {
    value: 'stitch_enabled',
    label: 'Stitch',
    name: 'stitch_disabled',
  },
  comment: {
    value: 'comment_enabled',
    label: 'Comment',
    name: 'comment_disabled',
  },
  duet: {
    value: 'duet_enabled',
    label: 'Duet',
    name: 'duet_disabled',
  },
};

export const TIKTOK_PROMOTION_OPTIONS = {
  isPromotion: {
    value: 'is_promotion',
    label: 'Promotes business',
    description: 'Indicate whether this video promotes a brand, product or service',
  },
  brandContent: {
    value: 'brand_content',
    label: 'Own',
    description: 'You are promoting yourself or your own business',
  },
  brandOrganic: {
    value: 'brand_organic',
    label: 'Other',
    description: 'You are promoting another brand or third-party',
  },
};

/**
 * {@link https://developers.facebook.com/docs/threads/overview#image-specifications}
 */
export const THREADS_ASPECT_RATIO_LIMITS = {
  images: {
    min: 0.01 / 1 - allowedDeviation,
    max: 10 / 1 + allowedDeviation,
  },
  video: {
    min: 0.01 / 1,
    max: 10 / 1,
  },
};

/**
 * {@link https://developers.facebook.com/docs/threads/overview#image-specifications}
 */
export const THREADS_RESOLUTIONS = {
  image: 1440 * 2, // bigger than 1440 will be scaled down
  video: 1920,
};

/**
 * {@link https://developers.facebook.com/docs/threads/overview#video-specifications}
 */
export const THREADS_DURATION_LIMITS = {
  video: {
    min: 1,
    max: 5 * 60, // 5 minutes
  },
};

export const THREADS_IMAGE_SIZES = {
  image: 8 * 1024 * 1024, // 8MB
};

/**
 * {@link https://developers.facebook.com/docs/threads/overview#video-specifications}
 */
export const THREADS_VIDEO_SIZES = {
  video: 1000 * 1024 * 1024, // 1GB
};
